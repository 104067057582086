<template>
	<ErrorModal :title="errorTitle" :message="errorMessage" @close="closeErrorModal" />
</template>

<script>
import ErrorModal from '@/components/modals/ErrorModal'

export default {
	name: 'Fallback',
	components: {
		ErrorModal
	},
	computed: {
		errorType() {
			return this.$route.params.errorType
		},
		errorTitle() {
			const errorTitles = {
				'label-not-activated': this.$t('message.front_not_activated_title'),
				'unsufficient-data': this.$t('message.front_wrong')
			}
			return errorTitles[this.errorType] || this.$t('message.front_wrong')
		},
		errorMessage() {
			const errorMessages = {
				'label-not-activated': this.$t('message.front_not_activated_message'),
				'unsufficient-data': this.$t('message.front_scan_again')
			}
			return errorMessages[this.errorType] || this.$t('message.front_scan_again')
		}
	},
	methods: {
		closeErrorModal() {
			this.$router.push('/')
		}
	}
}
</script>
